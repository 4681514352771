import axios from 'axios'
import { KeplerGlSchema } from 'kepler.gl/schemas'
import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { receiveMapConfig, removeDataset, addDataToMap, updateMap, removeLayer, layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, layerTextLabelChange, reorderLayer, interactionConfigChange, addFilter, setFilter, onMapClick, updateLayerAnimationSpeed, addLayer, layerTypeChange } from 'kepler.gl/actions'
import { renderBarikoiAttributions, uniqByIndex } from '../../utils/mapUtils'
import { sortOptionsAsc } from '../../utils/utils'
import { MapActionTypes } from './actionTypes'
import { NATIONS, REGIONS, AREAS, TERRITORIES, TOWNS, DISTRIBUTION_HOUSES, ROUTES, SR, OUTLETS, TRACE, API, BKOI_CLUSTERS, ALL_DISTRIBUTION_HOUSES } from '../../App.config'

// Import Map Configs
import initial_map_config from '../../configs/initial_map_config.js'

/////////
// MAP //
/////////

// Load Initial Custom Map with Two Default Styles such as OSM Liberty and Barikoi Dark
export function loadInitialCustomMap() {
  return dispatch => {
    const initialCustomMapConfig = initial_map_config
    const parsedInitialCustomMapConfig = KeplerGlSchema.parseSavedConfig(initialCustomMapConfig)
    dispatch( receiveMapConfig(parsedInitialCustomMapConfig) )

    // Render Barikoi Attributions
    renderBarikoiAttributions()
  }
}

// Fly To Locations
export function flyToLocation({ longitude, latitude, zoom }) {
  return dispatch => {
    dispatch( updateMap({ longitude, latitude, zoom: zoom ?? 18 }) )
  }
}

// Set Layer Size based on Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
  return (dispatch, getState) => {
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'point') {
        const radius = getRadiusWithZoom(zoom)
        dispatch( layerVisConfigChange(l, { radius: l.config.dataId === SR.SR_ROUTE_WAYPOINTS_DATA_ID ? 0 : radius }) )
      }

      if(l.type === 'icon') {
        const radius = getRadiusWithZoom(zoom)
        dispatch( layerVisConfigChange(l, { radius: l.config.dataId === DISTRIBUTION_HOUSES.DATA_ID || l.config.dataId === ALL_DISTRIBUTION_HOUSES.DATA_ID ? radius * 2 : radius * 1.2 }) )
      }

      if(l.type === 'trip' || l.type === 'geojson') {
        // Set Geojson Layer Thickness with Zoom
        const thickness = getThicknessWithZoom(zoom)
        dispatch( layerVisConfigChange(l, { thickness: l.config.dataId === SR.SR_ROUTE_DATA_ID ? thickness : thickness / 2 }) )
      }
    })
  }
}

// Set Selected Strike Rate
export function setSelectedStrikeRate(selectedStrikeRate) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_SELECTED_STRIKE_RATE, payload: selectedStrikeRate })
  }
}

// Set Channels
export function setChannels(channels) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_CHANNELS, payload: channels })
  }
}

// Set Selected Channel
export function setSelectedChannels(selectedChannels) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_SELECTED_CHANNEL, payload: selectedChannels })
  }
}

// Get Channels Data
export function getChannels() {
  return dispatch => {
    // Set Is Map Loading
    dispatch( setIsMapLoading(true) )

    // Get Channels Data from API
    axios.get(API.GET_CHANNELS)
      .then(res => {
        const { channels } = res.data

        // If Channels Invalid
        if(!channels?.length) {
          dispatch( setChannels([]) )

          // Set Is Map Loading
          dispatch( setIsMapLoading(false) )

          return
        }

        const channelOptions = sortOptionsAsc(channels.map(c => ({ ...c, value: c.id, label: c.name })))
        dispatch( setChannels(channelOptions) )

        // Set Is Map Loading
        dispatch( setIsMapLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setChannels([]) )

        // Set Is Map Loading
        dispatch( setIsMapLoading(false) )
      })
  }
}

/////////////
// NATIONS //
/////////////
// Load Nations Data To Map
export function loadNationsToMap(nationGeojson) {
  return (dispatch, getState) => {
    // Clear Nations
    dispatch ( clearNationsFromMap() )

    const dataInfo = { id: NATIONS.DATA_ID, label: NATIONS.DATA_LABEL }
    const data = processGeojson(nationGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        dispatch( colorNationsBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'none' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: NATIONS.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = NATIONS.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        NATIONS.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Filter By Selected Nation
    const selectedNation = getState()?.nav?.selectedNation ?? { value: 'All', label: 'All' }
    dispatch(
      filterNations({
        name: 'name',
        value: selectedNation?.value === 'All' ?
          [] :
          selectedNation?.value === 'None' ?
          [ '' ] :
          [ selectedNation?.name ]
      })
    )
  }
}

// Filter Nations
export function filterNations(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }
    
    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(NATIONS.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Clear Nations
export function clearNationsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(NATIONS.DATA_ID) )
  }
}

// Color Nations By Selected Color By Field
export function colorNationsBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === NATIONS.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Nations by Sales Quantity Level
        const _nationsDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _nationsDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          NATIONS.STRIKE_RATE_COLOR_MAPPINGS :
          NATIONS.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_nationsDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Nations',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

/////////////
// REGIONS //
/////////////
// Load Regions Data To Map
export function loadRegionsToMap(regionGeojson) {
  return (dispatch, getState) => {
    // Clear Regions
    dispatch ( clearRegionsFromMap() )

    const dataInfo = { id: REGIONS.DATA_ID, label: REGIONS.DATA_LABEL }
    const data = processGeojson(regionGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        dispatch( colorRegionsBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: REGIONS.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = REGIONS.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        REGIONS.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Filter By Selected Region
    const selectedRegion = getState()?.nav?.selectedRegion ?? { value: 'All', label: 'All' }
    dispatch(
      filterRegions({
        name: 'name',
        value: selectedRegion?.value === 'All' ?
          [] :
          selectedRegion?.value === 'None' ?
          [ '' ] :
          [ selectedRegion?.name ]
      })
    )
  }
}

// Filter Regions
export function filterRegions(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }

    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(REGIONS.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Color Regions By Selected Color By Field
export function colorRegionsBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === REGIONS.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Regions by Sales Quantity Level
        const _regionsDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _regionsDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          REGIONS.STRIKE_RATE_COLOR_MAPPINGS :
          REGIONS.SALES_QUANTITY_COLOR_MAPPINGS
        
        const colors = getColorPalettes(_regionsDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Regions',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

// Clear Regions
export function clearRegionsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(REGIONS.DATA_ID) )
  }
}

///////////
// AREAS //
///////////

// Load Areas Data To Map
export function loadAreasToMap(areaGeojson) {
  return (dispatch, getState) => {
    // Clear Areas
    dispatch ( clearAreasFromMap() )

    const dataInfo = { id: AREAS.DATA_ID, label: AREAS.DATA_LABEL }
    const data = processGeojson(areaGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        dispatch( colorAreasBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: AREAS.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = AREAS.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        AREAS.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Filter By Selected Area
    const selectedArea = getState()?.nav?.selectedArea ?? { value: 'All', label: 'All' }
    dispatch(
      filterAreas({
        name: 'name',
        value: selectedArea?.value === 'All' ?
          [] :
          selectedArea?.value === 'None' ?
          [ '' ] :
          [ selectedArea?.name ]
      })
    )
  }
}

// Filter Areas
export function filterAreas(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }

    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(AREAS.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Color Areas By Selected Color By Field
export function colorAreasBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === AREAS.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Areas by Sales Quantity Level
        const _areasDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _areasDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          AREAS.STRIKE_RATE_COLOR_MAPPINGS :
          AREAS.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_areasDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Areas',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

// Clear Areas
export function clearAreasFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(AREAS.DATA_ID) )
  }
}

/////////////////
// TERRITORIES //
/////////////////

// Load Territories Data To Map
export function loadTerritoriesToMap(territoryGeojson) {
  return (dispatch, getState) => {
    // Clear Territories
    dispatch ( clearTerritoriesFromMap() )

    // Build Territories Polygon Dataset
    const territoriesDataInfo = { id: TERRITORIES.DATA_ID, label: TERRITORIES.DATA_LABEL }
    const territoriesData = processGeojson(territoryGeojson)
    const territoriesDataset = { info: territoriesDataInfo, data: territoriesData }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: territoriesDataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach((l, i) => {
      // Territories Layer Config
      if(l.type === 'geojson' && l.config.dataId === territoriesDataInfo.id) {
        dispatch( colorTerritoriesBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: TERRITORIES.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = TERRITORIES.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        TERRITORIES.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Filter By Selected Territory
    const selectedTerritory = getState()?.nav?.selectedTerritory ?? { value: 'All', label: 'All' }
    dispatch(
      filterTerritories({
        name: 'name',
        value: selectedTerritory?.value === 'All' ?
          [] :
          selectedTerritory?.value === 'None' ?
          [ '' ] :
          [ selectedTerritory?.name ]
      })
    )
  }
}

// Filter Territories
export function filterTerritories(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }

    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(TERRITORIES.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Color Territories By Selected Color By Field
export function colorTerritoriesBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === TERRITORIES.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Territories by Sales Quantity Level
        const _territoriesDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _territoriesDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          TERRITORIES.STRIKE_RATE_COLOR_MAPPINGS :
          TERRITORIES.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_territoriesDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Territories',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

// Clear Territories
export function clearTerritoriesFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(TERRITORIES.DATA_ID) )
    dispatch( removeDataset(DISTRIBUTION_HOUSES.DATA_ID) )
  }
}

///////////
// TOWNS //
///////////

// Load Towns Data To Map
export function loadTownsToMap(townGeojson, distributionHouses=[]) {
  return (dispatch, getState) => {
    // Clear Towns
    dispatch ( clearTownsFromMap() )

    // Build Towns Polygon Dataset
    const townsDataInfo = { id: TOWNS.DATA_ID, label: TOWNS.DATA_LABEL }
    const townsData = processGeojson(townGeojson)
    const townsDataset = { info: townsDataInfo, data: townsData }

    // Build Distribution Houses Dataset
    const distributionHousesDataInfo = { id: DISTRIBUTION_HOUSES.DATA_ID, label: DISTRIBUTION_HOUSES.DATA_LABEL }
    const distributionHousesData = processRowObject(distributionHouses)
    const distributionHousesDataset = { info: distributionHousesDataInfo, data: distributionHousesData }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: [ townsDataset, distributionHousesDataset ], options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach((l, i) => {
      // Towns Layer Config
      if(l.type === 'geojson' && l.config.dataId === townsDataInfo.id) {
        dispatch( colorTownsBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: TOWNS.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = TOWNS.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        TOWNS.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }

      // Distribution Houses Layer Config
      if(l.config.dataId === distributionHousesDataInfo.id) {
        if(l.type === 'point') {
          // Remove Unnecessary Point Layer
          dispatch( removeLayer(i) )

        } else if(l.type === 'icon') {
          // Set Layer Label
          dispatch( layerConfigChange(l, { label: DISTRIBUTION_HOUSES.DATA_LABEL, color: DISTRIBUTION_HOUSES.COLOR }) )

          // Tooltip Configs
          const fieldsToShow = DISTRIBUTION_HOUSES.TOOLTIP_FIELDS
          const { tooltip } = getState().keplerGl.map.visState.interactionConfig
          tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
          dispatch( interactionConfigChange(tooltip) )

          // Add Filters
          DISTRIBUTION_HOUSES.FILTER_KEYS.forEach(k => {
            // Add Name Filter
            dispatch( addFilter(l.config.dataId) )
            const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
            if(filterIndex >= 0) {
              dispatch( setFilter(filterIndex, 'name', k.key) )
              
              if(k.type === 'string') {
                dispatch( setFilter(filterIndex, 'value', []) )

              } else if(k.type === 'number') {
                dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
              }
            }
          })
        }
      }
    })

    // Filter By Selected Town
    const selectedTown = getState()?.nav?.selectedTown ?? { value: 'All', label: 'All' }
    dispatch(
      filterTowns(
        {
          name: 'name',
          value: selectedTown?.value === 'All' ?
            [] :
            selectedTown?.value === 'None' ?
            [ '' ] :
            [ selectedTown?.name ]
        },
        { persistDistributionHouse: true }
      )
    )
  }
}

// Filter Towns
export function filterTowns(filter, options={ persistDistributionHouse: false }) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }
    
    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      // Filter Towns
      if(f?.dataId?.includes(TOWNS.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
      
      // Filter Distribution Houses
      if(f?.dataId?.includes(DISTRIBUTION_HOUSES.DATA_ID)) {
        if(!options.persistDistributionHouse) {
          if(f?.fieldType === 'string' && filter.name === 'name') {
            dispatch( setFilter(i, 'value', filter.value) )

          } else if(f?.fieldType === 'string' && filter.name === 'strike_rate' && filter.value?.length > 1) {
            const towns = getState()?.nav?.towns ?? []
            const filteredTowns = towns.filter(t => t.strike_rate >= filter.value[0] && t.strike_rate <= filter.value[1]).map(tm => tm.name)
            dispatch( setFilter(i, 'value', filteredTowns) )
          }
        }
      }
    })
  }
}

// Clear Towns
export function clearTownsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(TOWNS.DATA_ID) )
    dispatch( removeDataset(DISTRIBUTION_HOUSES.DATA_ID) )
  }
}

// Color Towns By Selected Color By Field
export function colorTownsBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === TOWNS.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Towns by Sales Quantity Level
        const _townsDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _townsDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          TOWNS.STRIKE_RATE_COLOR_MAPPINGS :
          TOWNS.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_townsDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Towns',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

////////////
// ROUTES //
////////////

// Load Routes Data To Map
export function loadRoutesToMap(routeGeojson) {
  return (dispatch, getState) => {
    // Clear Routes
    dispatch ( clearRoutesFromMap() )

    const dataInfo = { id: ROUTES.DATA_ID, label: ROUTES.DATA_LABEL }
    const data = processGeojson(routeGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        dispatch( colorRoutesBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: ROUTES.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = ROUTES.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        ROUTES.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Filter By Selected Route
    const selectedRoute = getState()?.nav?.selectedRoute ?? { value: 'All', label: 'All' }
    dispatch(
      filterRoutes({
        name: 'name',
        value: selectedRoute?.value === 'All' ?
          [] :
          selectedRoute?.value === 'None' ?
          [ '' ] :
          [ selectedRoute?.name ]
      })
    )
  }
}

// Filter Routes
export function filterRoutes(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }

    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(ROUTES.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Color Routes By Selected Color By Field
export function colorRoutesBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === ROUTES.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Routes by Sales Quantity Level
        const _routesDataset = getState().keplerGl.map.visState.datasets[ l.config.dataId ]
        const typeField = _routesDataset.fields.find(el => el.name === selectedColorBy.value)
        const colorMap = selectedColorBy?.value === 'strike_rate_level' ?
          ROUTES.STRIKE_RATE_COLOR_MAPPINGS :
          ROUTES.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_routesDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Routes',
          type: 'quantile'
        }

        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

// Clear Routes
export function clearRoutesFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(ROUTES.DATA_ID) )
  }
}

// Load SR Route To Map
export function loadSrRouteToMap(routeGeoJson, waypoints) {
  return (dispatch, getState) => {
    // Clear Previous SR Route Datasets
    dispatch( clearSrRouteFromMap() )

    // Build Route Dataset
    const routeDataInfo = { id: SR.SR_ROUTE_DATA_ID, label: SR.SR_ROUTE_DATA_LABEL }
    const routeData = processGeojson(routeGeoJson)
    const routeDataset = { info: routeDataInfo, data: routeData }

    // Build Waypoints Dataset
    const waypointsDataInfo = { id: SR.SR_ROUTE_WAYPOINTS_DATA_ID, label: SR.SR_ROUTE_WAYPOINTS_DATA_LABEL }
    const waypointsData = processRowObject(waypoints)
    const waypointsDataset = { info: waypointsDataInfo, data: waypointsData }

    // Options & Configs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    dispatch( addDataToMap({ datasets: [ waypointsDataset, routeDataset ], options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Add Route Layer Under Trip Layer
    dispatch( addLayer({ dataId: routeDataInfo.id }) )

    // Set Layer Colors
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      // Route Layer
      if(l.type === 'trip' && l.config.dataId === routeDataInfo.id) {
        dispatch( layerConfigChange(l, { label: 'Direction', color: [ 254, 210, 26 ] }) )
        dispatch( layerVisConfigChange(l, { thickness: 2, trailLength: 500 }) )
        dispatch( updateLayerAnimationSpeed(0.1) )

        // Play animation
        const playButton = document.querySelector(`.bottom-widget--container .bottom-widget--inner .animation-widget--inner .playback-controls .playback-control-button[data-for='animate-play-pause']`)
        if(playButton) {
          playButton.click()
        }

      } else if(!l.type && l.config.dataId === routeDataInfo.id) {
        // Route Layer under Trip Layer
        dispatch( layerTypeChange(l, 'geojson') )

        const routeLayer = getState()?.keplerGl?.map?.visState?.layers?.find(la => la.type === 'geojson' && la.config.dataId === routeDataInfo.id)

        if(routeLayer) {
          dispatch( layerConfigChange(routeLayer, { label: 'SO Route', columns: { geojson: { fieldIdx: 0, value: '_geojson' }}}))
          dispatch( layerVisConfigChange(routeLayer, { strokeColor: [ 237, 94, 221 ], thickness: 2 }) )

          // Set Line Width with Zoom
          const { zoom } = getState().keplerGl.map.mapState
          dispatch( setLayerSizeWithZoomLevel(zoom) )

          // Tooltip Configs
          const fieldsToShow = SR.SR_ROUTE_TOOLTIP_FIELDS
          const { tooltip } = getState().keplerGl.map.visState.interactionConfig
          tooltip.config.fieldsToShow[ routeLayer.config.dataId ] = fieldsToShow
          dispatch( interactionConfigChange(tooltip) )
        }

      } else if(l.type === 'point' && l.config.dataId === SR.SR_ROUTE_WAYPOINTS_DATA_ID) {
        // Waypoints Layer
        dispatch( layerConfigChange(l, { color: [ 18, 147, 154 ] }) )

        // Radius Zero
        dispatch( layerVisConfigChange(l, { radius: 0 }) )

        ////////////////////////////////////////
        // Set Waypoints Text Label to `name` //
        ////////////////////////////////////////
        const _waypointsDataset = getState().keplerGl.map.visState.datasets[ waypointsDataInfo.id ]
        const waypointSerialField = _waypointsDataset.fields.find(el => el.name === 'waypoint_serial')
        if(waypointSerialField) {
          dispatch( layerTextLabelChange(l, 0, 'field', waypointSerialField) )
        }

        // Set Text Label Color & Anchor
        l.config.textLabel.forEach((label, index) => {
          dispatch( layerTextLabelChange(l, index, 'color', [ 0, 0, 0 ]) )
          dispatch( layerTextLabelChange(l, index, 'anchor', 'end') )
        })

        // Set Line Width with Zoom
        const { zoom } = getState().keplerGl.map.mapState
        dispatch( setLayerSizeWithZoomLevel(zoom) )
      }
    })

    // Set SR Route Layer Visibility
    const isSrRouteOn = getState()?.nav?.isSrRouteOn ?? false
    dispatch( setSrRouteLayerVisibility(isSrRouteOn) )
  }
}

// Clear Optimized SR Route From Map
export function clearSrRouteFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(SR.SR_ROUTE_DATA_ID) )
    dispatch( removeDataset(SR.SR_ROUTE_WAYPOINTS_DATA_ID) )
  }
}

// Set SR Route Layer Visibility
export function setSrRouteLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const { layers } = getState()?.keplerGl?.map?.visState ?? {}
    layers.forEach(l => {
      if(l.config.dataId === SR.SR_ROUTE_DATA_ID || l.config.dataId === SR.SR_ROUTE_WAYPOINTS_DATA_ID) {
        dispatch( layerConfigChange(l, { isVisible }) )
      }
    })
  }
}

// Load SR Orders To Map
export function loadSrOrdersToMap(srOrders, srVisitedOutlets) {
  return (dispatch, getState) => {
    // Clear SR Orders
    dispatch ( clearSrOrdersFromMap() )

    const srOrdersDataInfo = { id: SR.SR_ORDERS_DATA_ID, label: SR.SR_ORDERS_DATA_LABEL }
    const srOrdersData = processRowObject(srOrders)
    const srOrdersDataset = { info: srOrdersDataInfo, data: srOrdersData }

    const srVisitedOutletsDataInfo = { id: SR.SR_VISITED_OUTLETS_DATA_ID, label: SR.SR_VISITED_OUTLETS_DATA_LABEL }
    const srVisitedOutletsData = processRowObject(srVisitedOutlets)
    const srVisitedOutletsDataset = { info: srVisitedOutletsDataInfo, data: srVisitedOutletsData }

    // Options & Conifgs
    const options = { readOnly: true }
    
    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: [ srOrdersDataset, srVisitedOutletsDataset ], options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    let layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      // SR Orders Layer
      if(l.type === 'point' && l.config.dataId === srOrdersDataInfo.id) {
        dispatch( layerConfigChange(l, { label: SR.SR_ORDERS_DATA_LABEL, color: SR.SR_ORDERS_COLOR }) )

        // Set Orders Text Label to `outlet_code`
        const ordersDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
        const outletCodeField = ordersDataset?.fields.find(el => el.name === 'outlet_code')
        if(outletCodeField) {
          dispatch( setTextLabel(l, outletCodeField) )

          // Set Text Label Color
          l.config.textLabel.forEach((label, li) => {
            dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
          })
        }

        // Tooltip Configs
        const fieldsToShow = SR.SR_ORDERS_TOOLTIP_FIELDS
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }

      // SR Visited Outlets Layer
      if(l.type === 'point' && l.config.dataId === srVisitedOutletsDataInfo.id) {
        dispatch( layerConfigChange(l, { label: SR.SR_VISITED_OUTLETS_DATA_LABEL, color: SR.SR_VISITED_OUTLETS_COLOR }) )

        // Set Outlets Text Label to `outlet_code`
        const srVisitedOutletsDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
        const outletCodeField = srVisitedOutletsDataset?.fields.find(el => el.name === 'outlet_code')
        if(outletCodeField) {
          dispatch( setTextLabel(l, outletCodeField) )

          // Set Text Label Color
          l.config.textLabel.forEach((label, li) => {
            dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
          })
        }

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = SR.SR_VISITED_OUTLETS_TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }
    })

    // Set SR Orders Layer Visibility
    const isSrOrdersLayerOn = getState()?.stat?.isSrOrdersLayerOn ?? false
    dispatch( setSrOrdersLayerVisibility(isSrOrdersLayerOn) )
    dispatch( setOutletsLayerVisibility(!isSrOrdersLayerOn) )
  }
}

// Clear Orders From Map
export function clearSrOrdersFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(SR.SR_ORDERS_DATA_ID) )
    dispatch( removeDataset(SR.SR_VISITED_OUTLETS_DATA_ID) )
  }
}

// Set SR Orders Layer Visibility
export function setSrOrdersLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const { layers } = getState()?.keplerGl?.map?.visState ?? {}
    layers.forEach(l => {
      if(l.config.dataId === SR.SR_ORDERS_DATA_ID || l.config.dataId === SR.SR_VISITED_OUTLETS_DATA_ID) {
        dispatch( layerConfigChange(l, { isVisible }) )
      }
    })
  }
}

// Load Order-Outlet LineString to Map
export function loadOrderOutletLineString(featureCollection) {
  return (dispatch, getState) => {
    // Clear SR Order-Outlet LineString
    dispatch ( clearOrderOutletLineString() )

    const srOrderOutletLineStringDataInfo = {
      id: SR.SR_ORDER_OUTLET_LINESTRING_DATA_ID,
      label: SR.SR_ORDER_OUTLET_LINESTRING_DATA_LABEL
    }
    const srOrderOutletLineStringData = processGeojson(featureCollection)
    const srOrderOutletLineStringDataset = {
      info: srOrderOutletLineStringDataInfo,
      data: srOrderOutletLineStringData
    }

    // Options & Conifgs
    const options = { readOnly: true }
    
    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: [ srOrderOutletLineStringDataset ], options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === srOrderOutletLineStringDataInfo.id) {
        dispatch( layerVisConfigChange(l, { strokeColor: [ 255, 191, 0 ], thickness: 0.5 }) )
      }
    })
  }
}

// Clear Order-Outlet LineString
export function clearOrderOutletLineString() {
  return dispatch => {
    dispatch( removeDataset(SR.SR_ORDER_OUTLET_LINESTRING_DATA_ID) )
  }
}

////////////
// OUTLETS //
////////////

// Load Outlets Data To Map
export function loadOutletsToMap(outlets, isTextLabelOn=true) {
  return (dispatch, getState) => {
    // Clear Outlets
    dispatch ( clearOutletsFromMap() )

    const dataInfo = { id: OUTLETS.DATA_ID, label: OUTLETS.DATA_LABEL }
    const data = processRowObject(outlets)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'point' && l.config.dataId === dataInfo.id) {
        dispatch( layerConfigChange(l, { label: 'Outlets', color: OUTLETS.COLOR }) )
        dispatch( colorOutletsBy(getState()?.map?.selectedColorBy ?? { value: 'None', label: 'None' }) )

        if(isTextLabelOn) {
          // Set Outlets Text Label to `name`
          const outletsDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
          const nameField = outletsDataset?.fields.find(el => el.name === 'business_name')
          if(nameField) {
            dispatch( setTextLabel(l, nameField) )

            // Set Text Label Color
            l.config.textLabel.forEach((label, li) => {
              dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
            })
          }
        }

        // Tooltip Configs
        const brands = getState()?.stat?.brands ?? []
        const sku = getState()?.stat?.sku ?? []
        let fieldsToShow = OUTLETS.TOOLTIP_FIELDS.concat(brands.map(b => ({ name: `total_gross_quantity (${ b.label })`, format: null })))
        fieldsToShow = fieldsToShow.concat(sku.map(s => ({ name: `total_gross_quantity (${ s.label })`, format: null })))
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )

        // Add Filters
        OUTLETS.FILTER_KEYS.forEach(k => {
          // Add Name Filter
          dispatch( addFilter(l.config.dataId) )
          const filterIndex = getState()?.keplerGl?.map?.visState?.filters?.findIndex(f => f?.dataId?.includes(l.config.dataId) && !f?.name?.length) ?? -1
          if(filterIndex >= 0) {
            dispatch( setFilter(filterIndex, 'name', k.key) )
            
            if(k.type === 'string') {
              dispatch( setFilter(filterIndex, 'value', []) )

            } else if(k.type === 'number') {
              dispatch( setFilter(filterIndex, 'value', [ Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER ]) )
            }
          }
        })
      }
    })

    // Update Map State
    dispatch( updateMap(mapState) )
  }
}

// Clear Outlets
export function clearOutletsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(OUTLETS.DATA_ID) )
    dispatch( clearSrRouteFromMap() )
  }
}

// Color Outlets By Selected Color By Field
export function colorOutletsBy(selectedColorBy) {
  return (dispatch, getState) => {
    if(!selectedColorBy || !selectedColorBy?.value) {
      return
    }

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'point' && l.config.dataId === OUTLETS.DATA_ID) {
        if(selectedColorBy.value === 'None') {
          // Dispatch Color Field By
          dispatch( layerVisualChannelConfigChange(l, { colorField: null }, 'color') )
          return
        }

        // Set Color Field by to distinguish Outlets by Sales Quantity Level
        const _outletsDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ] ?? null
        const typeField = _outletsDataset?.fields?.find(el => el.name === selectedColorBy.value) ?? null
        const colorMap = selectedColorBy?.value === 'channel_name' ?
          OUTLETS.CHANNEL_COLOR_MAPPINGS :
          selectedColorBy?.value === 'clp' ?
          OUTLETS.CLP_COLOR_MAPPINGS :
          selectedColorBy?.value === 'target_achieved_level' ?
          OUTLETS.TARGET_ACHIEVED_COLOR_MAPPINGS :
          OUTLETS.SALES_QUANTITY_COLOR_MAPPINGS
        const colors = getColorPalettes(_outletsDataset, typeField, colorMap)

        // Color Range
        const colorRange = {
          category: 'Barikoi',
          colors,
          name: 'Outlets',
          type: 'quantile'
        }

        // Dispatch Color Range
        dispatch( layerVisConfigChange(l, { colorRange }) )

        // Dispatch Color Field By
        dispatch( layerVisualChannelConfigChange(l, { colorField: typeField }, 'color') )
      }
    })
  }
}

// Set Outlets Layer Visibility
export function setOutletsLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const { layers } = getState()?.keplerGl?.map?.visState ?? {}
    layers.forEach(l => {
      if(l.config.dataId === OUTLETS.DATA_ID) {
        dispatch( layerConfigChange(l, { isVisible }) )
      }
    })
  }
}

// Filter Outlets
export function filterOutlets(filter) {
  return (dispatch, getState) => {
    if(!filter?.name || !filter?.value) {
      return
    }

    // Get Map Filter Index
    const mapFilters = getState()?.keplerGl?.map?.visState?.filters ?? []
    mapFilters.forEach((f, i) => {
      if(f?.dataId?.includes(OUTLETS.DATA_ID) && f?.name?.includes(filter.name)) {
        dispatch( setFilter(i, 'value', filter.value) )
      }
    })
  }
}

// Load Bkoi Outlets Data To Map
export function loadBkoiOutletsToMap(bkoiOutlets, isTextLabelOn=true) {
  return (dispatch, getState) => {
    // Clear Bkoi Outlets
    dispatch ( clearBkoiOutletsFromMap() )

    const dataInfo = { id: OUTLETS.BKOI_OUTLETS_DATA_ID, label: OUTLETS.BKOI_OUTLETS_DATA_LABEL }
    const data = processRowObject(bkoiOutlets)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Apply Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'point' && l.config.dataId === dataInfo.id) {
        dispatch( layerConfigChange(l, { label: 'Barikoi Outlets', color: OUTLETS.BKOI_OUTLETS_COLOR }) )

        if(isTextLabelOn) {
          // Set Bkoi Outlets Text Label to `name`
          const bkoiOutletsDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
          const nameField = bkoiOutletsDataset?.fields.find(el => el.name === 'business_name')
          if(nameField) {
            dispatch( setTextLabel(l, nameField) )

            // Set Text Label Color
            l.config.textLabel.forEach((label, li) => {
              dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
            })
          }
        }

        // Tooltip Configs
        const fieldsToShow = OUTLETS.BKOI_OUTLETS_TOOLTIP_FIELDS
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }
    })

    // Update Map State
    dispatch( updateMap(mapState) )
  }
}

// Clear Bkoi Outlets
export function clearBkoiOutletsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(OUTLETS.BKOI_OUTLETS_DATA_ID) )
  }
}

// Load Fixed Outlets Data To Map
export function loadFixedOutletsToMap(fixedOutlets, isTextLabelOn=true) {
  return (dispatch, getState) => {
    // Clear Fixed Outlets
    dispatch ( clearFixedOutletsFromMap() )

    const dataInfo = { id: OUTLETS.FIXED_OUTLETS_DATA_ID, label: OUTLETS.FIXED_OUTLETS_DATA_LABEL }
    const data = processRowObject(fixedOutlets)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Apply Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'point' && l.config.dataId === dataInfo.id) {
        dispatch( layerConfigChange(l, { label: 'Fixed Outlets', color: OUTLETS.FIXED_OUTLETS_COLOR }) )

        if(isTextLabelOn) {
          // Set Fixed Outlets Text Label to `name`
          const fixedOutletsDataset = getState()?.keplerGl?.map?.visState?.datasets[ l.config.dataId ]
          const nameField = fixedOutletsDataset?.fields.find(el => el.name === 'business_name')
          if(nameField) {
            dispatch( setTextLabel(l, nameField) )

            // Set Text Label Color
            l.config.textLabel.forEach((label, li) => {
              dispatch( layerTextLabelChange(l, li, 'color', [ 0, 0, 0 ]) )
            })
          }
        }

        // Tooltip Configs
        const fieldsToShow = OUTLETS.FIXED_OUTLETS_TOOLTIP_FIELDS
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }
    })

    // Update Map State
    dispatch( updateMap(mapState) )
  }
}

// Load Fixed Routes Data To Map
export function loadFixedRoutesToMap(fixedRouteGeojson) {
  return (dispatch, getState) => {
    // Clear Fixed Routes
    dispatch ( clearFixedRoutesFromMap() )

    const dataInfo = { id: ROUTES.FIXED_ROUTES_DATA_ID, label: ROUTES.FIXED_ROUTES_DATA_LABEL }
    const data = processGeojson(fixedRouteGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const { layers } = getState().keplerGl.map.visState
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: ROUTES.FIXED_ROUTES_STROKE_COLOR,
          thickness: 1,
          opacity: 0
        }) )

        // Tooltip Configs
        const fieldsToShow = ROUTES.FIXED_ROUTES_TOOLTIP_FIELDS
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }
    })
  }
}

// Clear Fixed Outlets
export function clearFixedOutletsFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(OUTLETS.FIXED_OUTLETS_DATA_ID) )
  }
}

// Clear Fixed Routes
export function clearFixedRoutesFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(ROUTES.FIXED_ROUTES_DATA_ID) )
  }
}

// Clear Bkoi Clusters
export function clearBkoiClustersFromMap() {
  return dispatch => {
    dispatch( onMapClick() )
    dispatch( removeDataset(BKOI_CLUSTERS.DATA_ID) )
  }
}

// Load Bkoi Clusters Data To Map
export function loadBkoiClustersToMap(bkoiClustersGeojson) {
  return (dispatch, getState) => {
    // Clear Bkoi Clusters
    dispatch ( clearBkoiClustersFromMap() )

    const dataInfo = { id: BKOI_CLUSTERS.DATA_ID, label: BKOI_CLUSTERS.DATA_LABEL }
    const data = processGeojson(bkoiClustersGeojson)
    const dataset = { info: dataInfo, data }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: dataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.type === 'geojson' && l.config.dataId === dataInfo.id) {
        // Apply Styles
        dispatch( layerVisConfigChange(l, {
          strokeOpacity: 0.8,
          strokeColor: BKOI_CLUSTERS.STROKE_COLOR,
          thickness: 1,
          opacity: 0.01
        }) )

        // Tooltip Configs
        const fieldsToShow = BKOI_CLUSTERS.TOOLTIP_FIELDS
        const { tooltip } = getState().keplerGl.map.visState.interactionConfig
        tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
        dispatch( interactionConfigChange(tooltip) )
      }
    })
  }
}

// Set Routes Layer Visibility
export function setRoutesLayerVisibility(isVisible) {
  return (dispatch, getState) => {
    const layers = getState()?.keplerGl?.map?.visState?.layers ?? []
    layers.forEach(l => {
      if(l.config.dataId === ROUTES.DATA_ID) {
        dispatch( layerConfigChange(l, { isVisible }) )
      }
    })
  }
}

// Load All Distribution Houses To Map
export function loadAllDistributionHousesToMap(allDistributionHouses) {
  return (dispatch, getState) => {
    // Clear All Distribution Houses From Map
    dispatch ( clearAllDistributionHousesFromMap() )

    // Build All Distribution Houses Dataset
    const allDistributionHousesDataInfo = { id: ALL_DISTRIBUTION_HOUSES.DATA_ID, label: ALL_DISTRIBUTION_HOUSES.DATA_LABEL }
    const allDistributionHousesData = processRowObject(allDistributionHouses)
    const allDistributionHousesDataset = { info: allDistributionHousesDataInfo, data: allDistributionHousesData }

    // Options & Conifgs
    const options = { readOnly: true }

    // Previous Map State
    const mapState = getState()?.keplerGl?.map?.mapState ?? {}

    // Add Data To Map
    dispatch( addDataToMap({ datasets: allDistributionHousesDataset, options }) )

    // Update Map State
    dispatch( updateMap(mapState) )

    // Apply Layer Configs
    const layers = getState()?.keplerGl?.map?.visState?.layers
    layers.forEach((l, i) => {
      // All Distribution Houses Layer Config
      if(l.config.dataId === allDistributionHousesDataInfo.id) {
        if(l.type === 'point') {
          // Remove Unnecessary Point Layer
          dispatch( removeLayer(i) )

        } else if(l.type === 'icon') {
          // Set Layer Label
          dispatch( layerConfigChange(l, { label: ALL_DISTRIBUTION_HOUSES.DATA_LABEL, color: ALL_DISTRIBUTION_HOUSES.COLOR }) )

          // Tooltip Configs
          const fieldsToShow = ALL_DISTRIBUTION_HOUSES.TOOLTIP_FIELDS
          const { tooltip } = getState().keplerGl.map.visState.interactionConfig
          tooltip.config.fieldsToShow[ l.config.dataId ] = fieldsToShow
          dispatch( interactionConfigChange(tooltip) )
        }
      }
    })
  }
}

// Clear All DistributionHousesFromMap
export function clearAllDistributionHousesFromMap() {
  return dispatch => {
    dispatch( removeDataset(ALL_DISTRIBUTION_HOUSES.DATA_ID) )
  }
}

/////////
// MAP //
/////////

// Set Is Map Loading
export function setIsMapLoading(isMapLoading) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_IS_MAP_LOADING, payload: isMapLoading })
  }
}

// Set Color By Options
export function setColorByOptions(colorByOptions) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_COLOR_BY_OPTIONS, payload: colorByOptions })
  }
}

// Set Selected Color By
export function setSelectedColorBy(selectedColorBy) {
  return dispatch => {
    dispatch({ type: MapActionTypes.SET_SELECTED_COLOR_BY, payload: selectedColorBy })
  }
}

// Get Color By Options Data
export function getColorByOptions() {
  return dispatch => {
    // Set Is Map Loading
    dispatch( setIsMapLoading(true) )

    const colorByOptions = [
      { value: 'sales_quantity_level', label: 'Sales Quantity' },
      { value: 'strike_rate_level', label: 'Strike Rate' },
      { value: 'target_achieved_level', label: 'Target Achieved' },
      { value: 'channel_name', label: 'Channel' },
      { value: 'clp', label: 'CLP' }
    ]

    dispatch( setColorByOptions(colorByOptions) )

    // Set Is Map Loading
    dispatch( setIsMapLoading(false) )
  }
}

// Set Layer Order. Trace on Top & Distribution Houses on Second, Outlets on top of SR Route
export function setLayerOrder() {
  return (dispatch, getState) => {
    const traceLayerIndex = getState()?.keplerGl?.map?.visState?.layers?.findIndex(l => l.type === 'icon' && l.config.dataId === TRACE.DATA_ID) ?? -1
    const distributionHousesLayerIndex = getState()?.keplerGl?.map?.visState?.layers?.findIndex(l => l.type === 'icon' && l.config.dataId === DISTRIBUTION_HOUSES.DATA_ID) ?? -1

    const topLayers = []
    if(traceLayerIndex >= 0) {
      topLayers.push(traceLayerIndex)
    }

    if(distributionHousesLayerIndex >= 0) {
      topLayers.push(distributionHousesLayerIndex)
    }

    // Re-order layers
    let layerOrder = getState()?.keplerGl?.map?.visState?.layerOrder ?? []
    layerOrder = layerOrder.filter(i => i !== traceLayerIndex && i !== distributionHousesLayerIndex)
    layerOrder.unshift(...topLayers)

    // Outlets on top of SR Route
    const outletsLayerIndex = getState()?.keplerGl?.map?.visState?.layers?.findIndex(l => l.type === 'point' && l.config.dataId === OUTLETS.DATA_ID) ?? -1
    const srRouteLayerIndex = getState()?.keplerGl?.map?.visState?.layers?.findIndex(l => l.type === 'geojson' && l.config.dataId === SR.SR_ROUTE_DATA_ID) ?? -1
    if(outletsLayerIndex >= 0 && srRouteLayerIndex >= 0) {
      const outletsLayerOrderIndex = layerOrder.findIndex(o => o === outletsLayerIndex)
      const srRouteLayerOrderIndex = layerOrder.findIndex(o => o === srRouteLayerIndex)

      if(outletsLayerOrderIndex >= 0 && srRouteLayerOrderIndex >= 0) {
        layerOrder[ outletsLayerOrderIndex ] = srRouteLayerIndex
        layerOrder[ srRouteLayerOrderIndex ] = outletsLayerIndex
      }
    }

    dispatch( reorderLayer(layerOrder) )
  }
}

// Set Text Label to `name` Property for trace Data
export function setTextLabel(layer, field) {
  return dispatch => {
    // Set Text label
    if(layer) {
      dispatch( layerTextLabelChange(layer, 0, 'field', field) )
    }
  }
}

///////////////
// UTILITIES //
///////////////

// Get Color Range based on unique data in dataset
function getColorPalettes(dataset, typeField, _colorMappings) {
  if(!dataset || !typeField) {
    return []
  }
  
  const colorMappings = _colorMappings ?? [
    { value: 'High Visibility', color: '#7aa457' },
    { value: 'Low Visibility', color: '#dc143c' },
    { value: 'Medium Visibility', color: '#a46cb7' }
  ]
  
  if (typeField?.tableFieldIndex > 0) {
    const uniqValues = uniqByIndex(dataset.allData, typeField?.tableFieldIndex-1)

    // If Zero Uniq Values
    if(!uniqValues?.length) {
      return []
    }

    // If Uniq Values found
    const colors = []
    colorMappings.forEach(c => {
      const isFound = uniqValues.find(u => u[typeField.tableFieldIndex-1] === c.value)
      if(isFound) {
        const color = c?.color
        const domainVal = c?.value
        if(color && domainVal) {
          colors.push(color)
        }
      }
    })

    return colors
  }
}

// Get Radius with Zoom
function getRadiusWithZoom(zoom) {
  let radius = 30

  if(zoom >= 14 && zoom < 15) {
    radius = 20

  } else if(zoom >= 15 && zoom < 16) {
    radius = 12.0

  } else if(zoom >= 16 && zoom < 17) {
    radius = 7.0

  } else if(zoom >= 17 && zoom < 18) {
    radius = 4.0

  } else if(zoom >= 18 && zoom < 19) {
    radius = 2.0

  } else if(zoom >= 19 && zoom < 20) {
    radius = 1.0

  } else if(zoom >= 20 && zoom < 22) {
    radius = 0.5

  } else if(zoom >= 22 && zoom < 23) {
    radius = 0.3

  } else if(zoom >= 23 && zoom < 24) {
    radius = 0.2

  } else if(zoom >= 24) {
    radius = 0.1
  }

  return radius
}

// Get Thickness With Zoom
function getThicknessWithZoom(zoom) {
  let thickness = 2

  if(zoom > 15 && zoom <= 16) {
    thickness = 1

  } else if(zoom > 16 && zoom <= 17) {
    thickness = 0.5

  } else if(zoom > 17 && zoom <= 18) {
    thickness = 0.4

  } else if(zoom > 18 && zoom <= 19) {
    thickness = 0.3

  } else if(zoom > 19 && zoom <= 20) {
    thickness = 0.2

  } else if(zoom > 20) {
    thickness = 0.1
  }

  return thickness
}